import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '@/views/LandingPageView.vue';
import LoginView from '@/views/LoginView.vue';
import PrivacyView from '@/views/PrivacyView.vue';
import OTPView from '@/views/OTPView.vue';
import SignUpView from '@/views/SignUpView.vue';
import RequestDetailView from '@/views/RequestDetail.vue';
import MusicianProfileView from '@/views/MusicianProfileView.vue';
import RequestExtraDetailsView from '@/views/RequestExtraDetailsView.vue';
import BandProfileView from '@/views/BandProfileView.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import MatchesView from '@/views/MatchesView.vue';
import RequestsView from '@/views/RequestsView.vue';
import BandsView from '@/views/BandsView.vue';

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/privacidad',
    name: 'Privacidad',
    component: PrivacyView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUpView
  },
  {
    path: '/otp',
    name: 'OTP',
    component: OTPView,
  },
  {
    path: '/musician-profile',
    name: 'MusicianProfileView',
    component: MusicianProfileView,
  },
  {
    path: '/request-detail',
    name: 'RequestDetailView',
    component: RequestDetailView
  },
  {
    path: '/request-extra-details',
    name: 'RequestExtraDetailsView',
    component: RequestExtraDetailsView
  },
  {
    path: '/band-profile',
    name: 'BandProfileView',
    component: BandProfileView
  },
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: 'matches',
        name: 'matches',
        component: MatchesView,
      },
      {
        path: 'requests',
        name: 'RequestsView',
        component: RequestsView,
      },
      {
        path: 'bands',
        name: 'BandsView',
        component: BandsView,
      }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
