<template>
    <PublicLayout>
        <div class="container">
            <h2>Encuentra el MaTCh perfecto!</h2>
            <form @submit.prevent="submitForm">
                <!-- Selección de qué detalles proporcionar -->
                <div class="form-group">
                    <label class="form-input-title">Incluye detalles para encontrar el MaTCh perfecto!</label>
                    <div class="checkbox-group">
                        <label class="checkbox-label">
                            <input type="checkbox" value="subgenerosInfluencias" v-model="selectedDetails" />
                            Subgéneros e Influencias
                        </label>
                        <label class="checkbox-label">
                            <input type="checkbox" value="disponibilidad" v-model="selectedDetails" />
                            Días y Horarios
                        </label>
                        <label class="checkbox-label">
                            <input type="checkbox" value="ubicacion" v-model="selectedDetails" />
                            Preferencias de Ubicación
                        </label>
                        <label class="checkbox-label">
                            <input type="checkbox" value="edad" v-model="selectedDetails" />
                            Rango de Edad
                        </label>
                    </div>
                </div>

                <!-- Sección de Disponibilidad -->
                <div v-if="selectedDetails.includes('disponibilidad')">
                    <div class="form-group">
                        <label for="days" class="form-input-title">¿Qué días te quedan mejor?</label>
                        <tag-input v-model="selectedDays" :available-options="availableDays" :limit="1" />
                    </div>

                    <div class="form-group">
                        <label for="time" class="form-input-title">¿En que momento del día?</label>
                        <tag-input v-model="selectedTimes" :available-options="availableTimes" :limit="1" />
                    </div>
                </div>

                <!-- Sección de Ubicación -->
                <div v-if="selectedDetails.includes('ubicacion')">
                    <div class="form-group">
                        <label for="state" class="form-input-title">¿En qué estado te encuentras?</label>
                        <select v-model="selectedState" @change="onStateChange">
                            <option disabled value="">Selecciona un estado</option>
                            <option v-for="state in availableStates" :key="state" :value="state">{{ state }}</option>
                        </select>
                    </div>

                    <div class="form-group" v-if="selectedState === 'CDMX'">
                        <label for="alcaldia" class="form-input-title">En qué alcaldía?</label>
                        <select v-model="selectedLocation">
                            <option disabled value="">Selecciona una alcaldía</option>
                            <option v-for="alcaldia in availableAlcaldias" :key="alcaldia" :value="alcaldia">{{ alcaldia }}</option>
                        </select>
                    </div>

                    <div class="form-group" v-else-if="selectedState === 'Estado de Mexico'">
                        <label for="municipio" class="form-input-title">¿En qué municipio?</label>
                        <select v-model="selectedLocation">
                            <option disabled value="">Selecciona un municipio</option>
                            <option v-for="municipio in availableMunicipios" :key="municipio" :value="municipio">{{ municipio }}</option>
                        </select>
                    </div>
                </div>

                <!-- Sección de Edad -->
                <div v-if="selectedDetails.includes('edad')">
                    <div class="form-group">
                        <label for="ageRange" class="form-input-title">¿Cuál es tu rango de edad?</label>
                        <select v-model="selectedAgeRange">
                            <option disabled value="">Selecciona un rango de edad</option>
                            <option value="Menos de 20 años">Menos de 20 años</option>
                            <option value="Entre 20 y 30 años">Entre 20 y 30 años</option>
                            <option value="Entre 30 y 40 años">Entre 30 y 40 años</option>
                            <option value="Más de 40 años">Más de 40 años</option>
                        </select>
                    </div>
                </div>

                <!-- Sección de Subgéneros e Influencias -->
                <div v-if="selectedDetails.includes('subgenerosInfluencias')">
                    <div class="form-group">
                        <label for="subgenres" class="form-input-title">¿Qué subgéneros te interesan?</label>
                        <tag-input v-model="selectedSubGenres" :available-options="availableSubGenres" :limit="4" />
                    </div>

                    <div class="form-group">
                        <label for="influences" class="form-input-title">¿Cuáles son tus influencias musicales?</label>
                        <tag-input v-model="selectedInfluences" :available-options="availableInfluences" :limit="5" />
                    </div>
                </div>

                <!-- Textarea para "Algo más" -->
                <div class="form-group">
                    <label for="additionalInfo" class="form-input-title">¿Algo más?</label>
                    <textarea v-model="additionalInfo"
                        placeholder="Cuéntanos cualquier otra especificación, como rango de edad preferido, si prefieres ensayar en salas, etc."
                        rows="4"></textarea>
                </div>

                <!-- Botón para continuar -->
                <div class="button-group">
                    <button class="cta-button" :disabled="!isFormValid">Enviar</button>
                </div>
            </form>
        </div>
    </PublicLayout>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout.vue';
import TagInput from '@/components/TagInput.vue';

export default {
    name: 'RequestExtraDetailsView',
    components: {
        PublicLayout,
        TagInput
    },
    data() {
        return {
            selectedDetails: [],  // Guardará las opciones seleccionadas (disponibilidad, ubicación, edad, subgéneros e influencias)

            // Disponibilidad
            selectedDays: [],
            availableDays: ['Entre semana', 'Fines de semana'], // Opciones de días
            selectedTimes: [],
            availableTimes: ['Mañana', 'Tarde', 'Noche'], // Opciones de horarios

            // Ubicación
            selectedState: '',
            availableStates: ['CDMX', 'Estado de Mexico'], // Opciones de estados
            selectedLocation: '',
            availableAlcaldias: ['Álvaro Obregón', 'Azcapotzalco', 'Benito Juárez', 'Coyoacán',
                'Cuajimalpa de Morelos', 'Cuauhtémoc', 'Gustavo A. Madero', 'Iztacalco', 'Iztapalapa',
                'La Magdalena Contreras', 'Miguel Hidalgo', 'Milpa Alta', 'Tláhuac', 'Tlalpan', 'Venustiano Carranza',
                'Xochimilco'],
            availableMunicipios: [
                'Acambay de Ruiz Castañeda', 'Acolman', 'Aculco', 'Almoloya de Alquisiras', 'Almoloya de Juárez',
                'Almoloya del Río', 'Amanalco', 'Amatepec', 'Amecameca', 'Apaxco', 'Atenco', 'Atizapán',
                'Atizapán de Zaragoza', 'Atlacomulco', 'Atlautla', 'Axapusco', 'Ayapango', 'Calimaya',
                'Capulhuac', 'Coacalco de Berriozábal', 'Coatepec Harinas', 'Cocotitlán', 'Coyotepec',
                'Cuautitlán', 'Cuautitlán Izcalli', 'Chalco', 'Chapa de Mota', 'Chapultepec', 'Chiautla',
                'Chicoloapan', 'Chiconcuac', 'Chimalhuacán', 'Donato Guerra', 'Ecatepec de Morelos',
                'Ecatzingo', 'Huehuetoca', 'Hueypoxtla', 'Huixquilucan', 'Isidro Fabela', 'Ixtapaluca',
                'Ixtapan de la Sal', 'Ixtapan del Oro', 'Ixtlahuaca', 'Xalatlaco', 'Jaltenco', 'Jilotepec',
                'Jilotzingo', 'Jiquipilco', 'Jocotitlán', 'Joquicingo', 'Juchitepec', 'Lerma', 'Malinalco',
                'Melchor Ocampo', 'Metepec', 'Mexicaltzingo', 'Morelos', 'Naucalpan de Juárez', 'Nextlalpan',
                'Nezahualcóyotl', 'Nicolás Romero', 'Nopaltepec', 'Ocoyoacac', 'Ocuilan', 'El Oro', 'Otumba',
                'Otzoloapan', 'Otzolotepec', 'Ozumba', 'Papalotla', 'La Paz', 'Polotitlán', 'Rayón',
                'San Antonio la Isla', 'San Felipe del Progreso', 'San Martín de las Pirámides',
                'San Mateo Atenco', 'San Simón de Guerrero', 'Santo Tomás', 'Soyaniquilpan de Juárez',
                'Sultepec', 'Tecámac', 'Tejupilco', 'Temamatla', 'Temascalapa', 'Temascalcingo', 'Temascaltepec',
                'Temoaya', 'Tenancingo', 'Tenango del Aire', 'Tenango del Valle', 'Teoloyucan', 'Teotihuacán',
                'Tepetlaoxtoc', 'Tepetlixpa', 'Tepotzotlán', 'Tequixquiac', 'Texcaltitlán', 'Texcalyacac',
                'Texcoco', 'Tezoyuca', 'Tianguistenco', 'Timilpan', 'Tlalmanalco', 'Tlalnepantla de Baz',
                'Tlatlaya', 'Toluca', 'Tonatico', 'Tultepec', 'Tultitlán', 'Valle de Bravo', 'Villa de Allende',
                'Villa del Carbón', 'Villa Guerrero', 'Villa Victoria', 'Xonacatlán', 'Zacazonapan', 'Zacualpan',
                'Zinacantepec', 'Zumpahuacán', 'Zumpango', 'Valle de Chalco Solidaridad', 'Luvianos',
                'San José del Rincón', 'Tonanitla'
            ],

            // Edad
            selectedAgeRange: '', // Rango de edad

            // Subgéneros e Influencias
            selectedSubGenres: [],
            availableSubGenres: [
                // Rock
                'Rock Clásico', 'Rock Progresivo', 'Rock Psicodélico', 'Hard Rock', 'Rock Alternativo',
                'Indie Rock', 'Punk Rock', 'Grunge', 'Rock Experimental', 'Glam Rock', 'Post-Rock',
                'Garage Rock', 'Folk Rock', 'Psychedelic Rock', 'Blues Rock', 'Stoner Rock', 'Math Rock',
                'Noise Rock', 'Pop Rock', 'Rock Gótico', 'Art Rock', 'Space Rock', 'Rock Sinfónico', 'Acid Rock',

                // Metal
                'Heavy Metal', 'Thrash Metal', 'Death Metal', 'Black Metal', 'Power Metal', 'Metal Sinfónico',
                'Metalcore', 'Nu Metal', 'Progressive Metal', 'Doom Metal', 'Folk Metal', 'Viking Metal',
                'Speed Metal', 'Glam Metal', 'Groove Metal', 'Deathcore', 'Melodic Death Metal', 'Grindcore',
                'Sludge Metal', 'Drone Metal', 'Stoner Metal', 'Industrial Metal', 'Symphonic Black Metal',

                // Pop
                'Pop Latino', 'Pop Rock', 'Electropop', 'Dance-pop', 'Teen Pop', 'Synthpop', 'Pop Punk', 'Indie Pop',
                'Power Pop', 'J-pop', 'K-pop', 'Baroque Pop', 'Art Pop', 'Chamber Pop', 'Soft Pop', 'Pop Rap',
                'Urban Pop', 'Folk Pop', 'Dream Pop', 'Pop Soul', 'Latin Pop',

                // Regional
                'Norteño', 'Banda', 'Ranchera', 'Grupero', 'Duranguense', 'Mariachi', 'Huapango', 'Tejano', 'Sierreño',
                'Corridos', 'Corridos Tumbados', 'Corrido Norteño', 'Tambora', 'Son Jarocho', 'Quebradita',
                'Cumbia Norteña', 'Cumbia Andina', 'Cumbia Texana', 'Cumbia Villera', 'Banda Sinaloense',

                // Electrónica
                'House', 'Techno', 'Trance', 'Dubstep', 'Drum and Bass', 'Hardstyle', 'Ambient', 'Progressive House',
                'Electro House', 'Deep House', 'Acid House', 'Psytrance', 'Hard Trance', 'Tech House', 'Minimal',
                'Future Bass', 'Future House', 'Glitch Hop', 'Chillout', 'Downtempo', 'Electropop', 'Trip-hop',

                // Jazz
                'Bebop', 'Smooth Jazz', 'Jazz Fusión', 'Cool Jazz', 'Jazz Contemporáneo', 'Jazz Afro-Cubano',
                'Jazz Experimental', 'Free Jazz', 'Hard Bop', 'Jazz Modal', 'Jazz Swing', 'Big Band Jazz',
                'Soul Jazz', 'Post-bop', 'Vocal Jazz',

                // Soul
                'Neo Soul', 'Soul Funk', 'Motown', 'Blue-eyed Soul', 'Gospel Soul', 'Southern Soul', 'Soul Jazz',
                'Philly Soul', 'Northern Soul', 'Funk Soul', 'Soul R&B',

                // Blues
                'Delta Blues', 'Chicago Blues', 'Electric Blues', 'Blues Rock', 'Texas Blues', 'Jump Blues',
                'Acoustic Blues', 'Piedmont Blues', 'Swamp Blues', 'Country Blues', 'Boogie Woogie',

                // Otros (menor cantidad)
                'Reggae Ska', 'Ska Punk', 'Ska Tradicional', 'Salsa Romántica', 'Salsa Dura', 'Salsa Fusión',
                'Reggaeton Romántico', 'Trap Latino', 'Dembow', 'Rap Latino', 'Hip-Hop Jazz', 'Boom Bap', 'Trap',
                'Freestyle Rap'
            ],
            selectedInfluences: [],
            availableInfluences: [
                // Rock
                'Led Zeppelin', 'The Rolling Stones', 'Queen', 'Pink Floyd', 'The Who', 'AC/DC', 'David Bowie',
                'The Beatles', 'Nirvana', 'The Strokes', 'Pearl Jam', 'The White Stripes', 'Radiohead', 'Foo Fighters',
                'Red Hot Chili Peppers', 'Oasis', 'The Black Keys', 'Arctic Monkeys', 'Green Day', 'U2',

                // Metal
                'Metallica', 'Iron Maiden', 'Black Sabbath', 'Slayer', 'Megadeth', 'Judas Priest', 'Pantera',
                'Slipknot', 'Rammstein', 'System of a Down', 'Korn', 'Sepultura', 'Anthrax', 'Dream Theater',
                'Nightwish', 'Arch Enemy', 'Avenged Sevenfold', 'Lamb of God', 'Opeth', 'Mastodon',

                // Pop
                'Michael Jackson', 'Madonna', 'Prince', 'Britney Spears', 'Lady Gaga', 'Justin Timberlake',
                'Taylor Swift', 'Beyoncé', 'Ariana Grande', 'Dua Lipa', 'Bruno Mars', 'Katy Perry', 'Billie Eilish',
                'The Weeknd', 'Ed Sheeran', 'Shakira', 'Rihanna', 'Harry Styles', 'Selena Gomez', 'Miley Cyrus',

                // Regional (Mexicano)
                'Vicente Fernández', 'Joan Sebastian', 'Pepe Aguilar', 'Los Tigres del Norte', 'Banda El Recodo',
                'Jenni Rivera', 'Ariel Camacho', 'Julión Álvarez', 'Luis Miguel', 'Pedro Infante', 'Ángela Aguilar',
                'Christian Nodal', 'Calibre 50', 'La Arrolladora Banda El Limón', 'Los Tucanes de Tijuana',
                'El Fantasma', 'Gerardo Ortiz', 'Marco Antonio Solís', 'Alfredo Olivas', 'Chalino Sánchez',

                // Electrónica
                'Daft Punk', 'Kraftwerk', 'The Chemical Brothers', 'Deadmau5', 'Skrillex', 'Justice', 'David Guetta',
                'Avicii', 'Tiesto', 'Calvin Harris', 'Armin van Buuren', 'Martin Garrix', 'Disclosure', 'Paul Oakenfold',
                'Moby', 'Fatboy Slim', 'Underworld', 'Above & Beyond', 'Flume', 'Diplo',

                // Jazz
                'Miles Davis', 'John Coltrane', 'Herbie Hancock', 'Chick Corea', 'Pat Metheny', 'Wayne Shorter',
                'Keith Jarrett', 'Diana Krall', 'Wynton Marsalis', 'Esperanza Spalding', 'Stan Getz', 'Charles Mingus',
                'Bill Evans', 'George Benson', 'Chet Baker', 'Sonny Rollins', 'Norah Jones', 'Brad Mehldau', 'Joshua Redman', 'Snarky Puppy',

                // Soul
                'Stevie Wonder', 'Marvin Gaye', 'Aretha Franklin', 'Al Green', 'Curtis Mayfield', 'James Brown', 'Sade',
                'Erykah Badu', 'D\'Angelo', 'Amy Winehouse', 'Sam Cooke', 'Otis Redding', 'Alicia Keys', 'Lauryn Hill',
                'Jill Scott', 'The Temptations', 'Earth, Wind & Fire', 'Ray Charles', 'Solange', 'Janelle Monáe',

                // Blues
                'B.B. King', 'Muddy Waters', 'John Lee Hooker', 'Eric Clapton', 'Buddy Guy', 'Bonnie Raitt', 'Robert Johnson',
                'Stevie Ray Vaughan', 'Howlin\' Wolf', 'Albert King', 'Freddie King', 'Gary Clark Jr.', 'Taj Mahal',
                'Joe Bonamassa', 'Jeff Beck', 'T-Bone Walker', 'Etta James', 'Johnny Winter', 'Susan Tedeschi', 'Rory Gallagher',

                // Otros (menos representados)
                'Daddy Yankee', 'Celia Cruz', 'Rubén Blades', 'Residente', 'Bad Bunny', 'Los Fabulosos Cadillacs',
                'Ivy Queen', 'Vico C', 'Anuel AA', 'J Balvin', 'Tego Calderón', 'Cypress Hill', 'Dr. Dre',
                'Snoop Dogg', 'Eminem', 'Beastie Boys', 'Nas', 'Outkast', 'Kendrick Lamar', 'Travis Scott'
            ],
            additionalInfo: '',  // Información adicional
        };
    },
    computed: {
        isFormValid() {
            // El formulario es válido si se ha escrito algo en la caja de texto "¿Algo más?"
            return this.additionalInfo.trim() !== '';
        }
    },
    methods: {
        onStateChange() {
            this.selectedLocation = ''; // Resetear la selección de alcaldía/municipio cuando cambie el estado
        },
        submitForm() {
            // Lógica para enviar el formulario
            console.log("Formulario enviado con los siguientes datos:", {
                selectedDetails: this.selectedDetails,
                selectedDays: this.selectedDays,
                selectedTimes: this.selectedTimes,
                selectedState: this.selectedState,
                selectedLocation: this.selectedLocation,
                selectedAgeRange: this.selectedAgeRange,
                selectedSubGenres: this.selectedSubGenres,
                selectedInfluences: this.selectedInfluences,
                additionalInfo: this.additionalInfo
            });
            this.$router.push({ name: 'matches' });
        }
    }
};
</script>

<style scoped>
.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.checkbox-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1em;
    color: var(--neutral-light);
}

.checkbox-label input[type="checkbox"] {
    margin-right: 10px;
    vertical-align: middle;
    width: auto;
}

.form-input-title {
    margin-bottom: 10px;
}
</style>
