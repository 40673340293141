<template>
    <PublicLayout>
        <div class="container">
            <h2>{{ pageTitle }}</h2>
            <form @submit.prevent="submitForm">
                <!-- Tipo de Solicitud -->
                <label for="requestType" class="form-input-title">¿Qué estás buscando?</label>
                <tag-input v-model="selectedRequestType" :available-options="availableRequestType" :limit="1" />

                <!-- Tipo de Música -->
                <label for="musicType" class="form-input-title">¿Qué tipo de música?</label>
                <tag-input v-model="selectedMusicType" :available-options="availableMusicTypes" :limit="2" />

                <!-- Objetivo -->
                <label for="objective" class="form-input-title">¿Cuál es tu objetivo?</label>
                <tag-input v-model="selectedObjectives" :available-options="availableObjectives" :limit="1" />

                <!-- Botones para continuar -->
                <div v-if="isFormValid && bandProfileRequired">
                    <div class="button-group">
                        <button @click="goToBandProfile" class="cta-button">Continuar</button>
                    </div>
                </div>
                <div v-if="isFormValid && !bandProfileRequired" class="informative-text">
                    ¡Estás más cerca de tu MaTCh! Agrega más detalles para aumentar tus oportunidades de encontrar la
                    banda o los músicos perfectos.
                </div>
                <div v-if="isFormValid && !bandProfileRequired">
                    <div class="button-group">
                        <button @click="goToSimpleSearch" class="cta-button">Quiero encontrar mi MaTCh</button>
                    </div>
                </div>
            </form>
            <FullScreenLoader :isLoading="isLoading" />
        </div>
    </PublicLayout>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout.vue';
import TagInput from '@/components/TagInput.vue';
import FullScreenLoader from '@/components/FullScreenLoader.vue';

export default {
    name: 'RequestDetailsView',
    components: {
        PublicLayout,
        TagInput,
        FullScreenLoader
    },
    data() {
        const storedPageTitle = localStorage.getItem('pageTitle') || 'Cuéntanos sobre ti';
        const musicianProfile = JSON.parse(localStorage.getItem('musicianProfile')) || {};

        const initialSelectedMusicType = musicianProfile.music_type === 'BOTH'
            ? ['Originales', 'Covers']
            : musicianProfile.music_type === 'ORIGINAL'
                ? ['Originales']
                : musicianProfile.music_type === 'COVERS'
                    ? ['Covers']
                    : [];

        const initialSelectedObjectives = musicianProfile.interest === 'HOBBY'
            ? ['Hobby']
            : musicianProfile.interest === 'WORK'
                ? ['Trabajo']
                : [];

        return {
            selectedRequestType: [],
            selectedMusicType: initialSelectedMusicType,
            selectedObjectives: initialSelectedObjectives,
            availableRequestType: ['Una banda', 'Músicos para mi banda'],
            availableMusicTypes: ['Originales', 'Covers'],
            availableObjectives: ['Hobby', 'Trabajo'],
            pageTitle: storedPageTitle,
            isLoading: false
        };
    },
    computed: {
        isFormValid() {
            return this.selectedRequestType.length > 0 &&
                this.selectedMusicType.length > 0 &&
                this.selectedObjectives.length > 0;
        },
        bandProfileRequired() {
            return this.selectedRequestType.includes('Músicos para mi banda');
        }
    },
    methods: {
        async submitForm() {
            if (this.isLoading) return;
            this.isLoading = true;

            const token = localStorage.getItem('authToken');
            const musicType = this.selectedMusicType.length === 2 ? 'BOTH' : (this.selectedMusicType[0] === 'Originales' ? 'ORIGINAL' : 'COVERS');
            const interest = this.selectedObjectives[0] === 'Hobby' ? 'HOBBY' : 'WORK';

            const requestBody = {
                user: {
                    enrollment_status: "3"
                },
                musician_profile: {
                    music_type: musicType,
                    interest: interest,
                }
            };

            try {
                const response = await fetch('https://api.hey-matchr.com/users/enrichment', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestBody)
                });

                const data = await response.json();
                const parsedBody = JSON.parse(data.body);

                if (parsedBody.message === "Profile enrichment process completed.") {
                    localStorage.setItem('userProfile', JSON.stringify(parsedBody.user));
                    localStorage.setItem('musicianProfile', JSON.stringify(parsedBody.musician_profile));

                    if (this.selectedRequestType.includes('Una banda')) {
                        const requestSuccess = await this.createRequest(parsedBody.musician_profile);

                        if (requestSuccess) {
                            await this.updateEnrollmentStatus(token);
                        }
                    }
                    return true;
                } else {
                    console.log("La respuesta de la API no coincide con el mensaje esperado.");
                    return false;
                }
            } catch (error) {
                console.error("Error en la solicitud:", error);
                return false;
            } finally {
                this.isLoading = false;
            }
        },
        async createRequest(musicianProfile) {
            const token = localStorage.getItem('authToken');
            const requestBody = {
                genres: musicianProfile.genres,
                level: musicianProfile.skill_level,
                music_type: musicianProfile.music_type,
                interest: musicianProfile.interest,
                instrument: musicianProfile.main_instrument,
                request_type: 'BAND'
            };

            try {
                const response = await fetch('https://api.hey-matchr.com/requests/create', {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestBody)
                });

                const data = await response.json();
                const parsedResponse = JSON.parse(data.body);

                if (data.statusCode === 200) {
                    if (parsedResponse.request && parsedResponse.request.request_id) {
                        sessionStorage.setItem('requestID', parsedResponse.request.request_id);
                    }
                    return true;
                } else {
                    console.log("Error creando el request:", parsedResponse);
                    return false;
                }
            } catch (error) {
                console.error("Error en createRequest:", error);
                return false;
            }
        },
        async updateEnrollmentStatus(token) {
            const updateRequest = {
                user: {
                    enrollment_status: "4"
                }
            };
            try {
                const responseUpdate = await fetch('https://api.hey-matchr.com/users/enrichment', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updateRequest)
                });

                const dataUpdated = await responseUpdate.json();
                const parsedUpdatedBody = JSON.parse(dataUpdated.body);

                if (parsedUpdatedBody.message === "Profile enrichment process completed.") {
                    localStorage.setItem('userProfile', JSON.stringify(parsedUpdatedBody.user));
                    return true;
                } else {
                    console.log("La respuesta de la API no coincide con el mensaje esperado.");
                    return false;
                }
            } catch (error) {
                console.error("Error en la solicitud:", error);
                return false;
            }
        },
        async goToExtraDetails() {
            const success = await this.submitForm();
            if (success) {
                this.$router.push({ name: 'RequestExtraDetailsView' });
            }
        },
        async goToSimpleSearch() {
            const success = await this.submitForm();
            if (success) {
                this.$router.push({ name: 'matches' });
            }
        },
        async goToBandProfile() {
            const success = await this.submitForm();
            if (success) {
                this.$router.push({ name: 'BandProfileView' });
            }
        }
    },
    mounted() {
        document.title = this.pageTitle;
    },
    watch: {
        pageTitle(newTitle) {
            document.title = newTitle || 'Encuentra tu Match';
        }
    }
};
</script>



<style scoped>
.button-group {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}

button.cta-button {
    flex: 1;
    margin: 0 10px;
    padding: 10px;
}

button.cta-button:disabled {
    background-color: #888;
    cursor: not-allowed;
}

.link-container {
    display: flex;
    justify-content: center;
}
</style>
