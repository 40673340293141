<template>
  <PublicLayout>
    <div class="container">
      <h2>Página en Construcción</h2>
      <p>Estamos trabajando en algo increíble. ¡Pronto podrás iniciar sesión y descubrir MaTChR!</p>
      <a href="https://form.jotform.com/242176060238048" class="cta-button">¡Pre-regístrate Ahora!</a>
      <p class="pre-registro-msg">No te pierdas el lanzamiento. Completa tu pre-registro aquí.</p>
    </div>
  </PublicLayout>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout.vue';

export default {
  name: 'LoginView',
  components: {
    PublicLayout
  },
  metaInfo() {
    return {
      title: 'Iniciar Sesión - MaTChR'
    };
  }
};
</script>

<style scoped>

.pre-registro-msg {
  font-size: 1.1em;
  color: var(--secondary-color);
  margin-top: 15px;
}
</style>
