<template>
  <div class="sidebar-container">
    <aside :class="['sidebar', { collapsed: isSidebarCollapsed }]">
      <nav>
        <ul>
          <li :class="{ active: selectedSection === 'matches' }" @click="selectSection('matches')">
            <div class="icon-wrapper">
              <i class="fa-solid fa-compact-disc"></i>
            </div>
            <span class="text" v-if="!isSidebarCollapsed">MaTChEs</span>
          </li>
          <li :class="{ active: selectedSection === 'RequestsView' }" @click="selectSection('RequestsView')">
            <div class="icon-wrapper">
              <i class="fa-solid fa-paper-plane"></i>
            </div>
            <span class="text" v-if="!isSidebarCollapsed">Solicitudes</span>
          </li>
          <li :class="{ active: selectedSection === 'BandsView' }" @click="selectSection('BandsView')">
            <div class="icon-wrapper">
              <i class="fa-solid fa-bolt"></i>
            </div>
            <span class="text" v-if="!isSidebarCollapsed">Bandas</span>
          </li>
        </ul>
      </nav>

      <!-- Botón con ícono -->
      <button @click="toggleSidebar" class="toggle-sidebar-btn">
        <i :class="isSidebarCollapsed ? 'fas fa-chevron-right' : 'fas fa-chevron-left'"></i>
      </button>
    </aside>
  </div>
</template>

<script>
export default {
  name: 'AppSidebar',
  data() {
    return {
      selectedSection: 'matches', // Selecciona la primera opción por defecto
    };
  },
  props: {
    isSidebarCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleSidebar() {
      this.$emit('toggle-sidebar');
    },
    selectSection(section) {
      this.selectedSection = section; // Cambia la sección seleccionada
      this.goTo(section); // Llama a la función para cambiar de vista
    },
    goTo(section) {
      this.$emit('go-to', section);
    },
  },
  mounted() {
    this.selectSection('matches'); // Selecciona 'matches' por defecto al montar el componente
  },
};
</script>

<style scoped>
.sidebar-container {
  background-color: var(--color-neutral-1); 
  height: 100%;
}

.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--color-dark-2); /* Blanco suave para el fondo */
  width: 180px;
  height: 100%;
  box-sizing: border-box;
  transition: width 0.3s ease; /* Transición suave para la anchura del sidebar */
  border-radius: 0px 12px 12px 0px;
}

.sidebar.collapsed {
  width: 80px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  color: var(--color-neutral-1); /* Texto oscuro */
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 0.9em;
}

/* Efecto hover para todas las opciones */
.sidebar li:hover {
  background-color: var(--tag-text-color); /* Fondo oscuro para hover */
  border-radius: 10px;
}

/* Estilos para el item activo */
.sidebar li.active {
  color: var(--color-dark-2); /* Texto oscuro al estar activo */
}

/* Estilo del ícono con glow rosa */
.sidebar li.active .icon-wrapper {
  box-shadow: 0px 0px 10px 2px var(--color-primary-bold); /* Glow usando rosa vibrante */
}

.sidebar li span.text {
  margin-left: 12px;
  font-size: 1em;
  color: var(--color-neutral-1); 
  white-space: nowrap; /* Evita que el texto se quiebre */
  transition: opacity 0.3s ease; /* Transición suave para la opacidad del texto */
}

/* Estilo de los íconos */
.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border: 2px solid var(--color-primary-bold); /* Borde rosa vibrante */
  border-radius: 50%;
  transition: border-color 0.3s, box-shadow 0.3s;
  flex-shrink: 0; /* Evita que el ícono se deforme o cambie de tamaño */
}

.icon-wrapper i {
  font-size: 1.6em;
  color: var(--color-neutral-1); /* Texto claro */
  transition: none; /* Mantiene el ícono sin cambios */
}

/* Botón de colapsar el sidebar */
.toggle-sidebar-btn {
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--color-primary-bold); /* Botón con rosa vibrante */
  color: var(--color-neutral-1); /* Texto claro */
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  transition: background-color 0.3s ease;
  padding: 0;
}

.sidebar.collapsed .toggle-sidebar-btn {
  right: -30px;
}

.sidebar.collapsed .icon-wrapper {
  width: 45px; /* Mantén el mismo tamaño del ícono cuando colapsa */
  height: 45px;
}

.sidebar.collapsed li.active .icon-wrapper {
  box-shadow: 0px 0px 10px 2px var(--color-primary-bold); /* Glow en la vista colapsada */
}

.sidebar.collapsed li span.text {
  display: none; /* Elimina el texto en la vista colapsada */
}

nav {
padding:15px;
}
</style>
