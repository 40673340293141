<template>
    <PublicLayout>
        <div class="container">
            <h2>{{ pageTitle }}</h2>
            <form @submit.prevent="submitForm" class="form-layout">
                <!-- Nombre Input -->
                <div class="form-group">
                    <label for="name" class="form-input-title">Nombre (Apodo)</label>
                    <input id="name" type="text" v-model="name" placeholder="Escribe tu nombre" />
                </div>

                <!-- Nivel de Interés -->
                <div class="form-group">
                    <label for="level" class="form-input-title">Nivel</label>
                    <tag-input v-model="selectedLevel" :available-options="availableLevels" :limit="1" />
                </div>

                <!-- Instrumento Principal Input -->
                <div class="form-group">
                    <label for="instrument" class="form-input-title">Instrumento Principal</label>
                    <tag-input v-model="selectedInstruments" :available-options="availableInstruments" :limit="1" />
                </div>

                <!-- Géneros de Interés -->
                <div class="form-group">
                    <label for="genres" class="form-input-title">Géneros de Interés</label>
                    <tag-input v-model="selectedGenres" :available-options="availableGenres" :limit="4" />
                </div>

                <!-- Botón para enviar -->
                <div class="button-group">
                    <button :disabled="!isFormValid" class="cta-button">Continuar</button>
                </div>
            </form>

            <!-- Loader en pantalla completa -->
            <FullScreenLoader :isLoading="isLoading" />
        </div>
    </PublicLayout>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout.vue';
import TagInput from '@/components/TagInput.vue';
import FullScreenLoader from '@/components/FullScreenLoader.vue';

export default {
    name: 'MusicianProfileView',
    props: {
        userProfile: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        PublicLayout,
        TagInput,
        FullScreenLoader
    },
    data() {
        const storedUserProfile = JSON.parse(localStorage.getItem('userProfile') || '{}');
        const storedPageTitle = localStorage.getItem('pageTitle') || 'Cuentanos sobre ti';

        return {
            name: storedUserProfile.alias || '',  // Inicializar con el nombre si existe en localStorage
            selectedLevel: [],
            availableLevels: ['Basico', 'Intermedio', 'Avanzado'],
            selectedInstruments: [],
            availableInstruments: ['Guitarra', 'Bajo', 'Bateria', 'Teclado', 'Voz', 'Percusiones'],
            selectedGenres: [],
            availableGenres: ['Rock', 'Metal', 'Pop', 'Regional', 'Reggaeton', 'Hip-Hop', 'Rap', 'Ska', 'Cumbia', 'Salsa', 'Electronica', 'Jazz', 'Baladas', 'Boleros'],
            isLoading: false,
            pageTitle: storedPageTitle  // Usar el título almacenado en localStorage
        };
    },
    mounted() {
        document.title = this.pageTitle;
    },

    computed: {
        isFormValid() {
            return this.name.length >= 3 && this.selectedLevel.length > 0 && this.selectedInstruments.length > 0 && this.selectedGenres.length > 0;
        }
    },
    watch: {
        pageTitle(newTitle) {
            document.title = newTitle || 'Cuentanos sobre ti';
        }
    },
    methods: {
        async submitForm() {
            if (this.isFormValid) {
                this.isLoading = true;
                const levelMapping = {
                    Basico: 'BEGINNER',
                    Intermedio: 'INTERMEDIATE',
                    Avanzado: 'ADVANCED'
                };
                const mappedLevel = levelMapping[this.selectedLevel[0]];
                const requestBody = {
                    user: {
                        alias: this.name,
                        enrollment_status: "2" 
                    },
                    musician_profile: {
                        genres: this.selectedGenres,
                        main_instrument: this.selectedInstruments[0],
                        skill_level: mappedLevel,
                    }
                };

                const token = localStorage.getItem('authToken');

                try {
                    const response = await fetch('https://api.hey-matchr.com/users/enrichment', {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(requestBody)
                    });

                    const data = await response.json();
                    console.log("Respuesta de la API:", data);

                    // Parsear data.body ya que contiene un string JSON
                    const parsedBody = JSON.parse(data.body);

                    if (parsedBody.message === "Profile enrichment process completed.") {
                        // Extraer el enrollment_status actualizado y almacenarlo en localStorage
                        const updatedEnrollmentStatus = parsedBody.user.enrollment_status;
                        localStorage.setItem('enrollmentStatus', updatedEnrollmentStatus);

                        // Redirigir a la siguiente vista
                        console.log("Redirigiendo a RequestDetailView");
                        this.$router.push({ name: 'RequestDetailView' });
                    } else {
                        console.log("La respuesta de la API no coincide con el mensaje esperado.");
                    }
                } catch (error) {
                    console.error("Error en la solicitud:", error);
                } finally {
                    this.isLoading = false;
                }
            }
        }
    }
};
</script>

<style scoped>
.button-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

button.cta-button {
    padding: 10px 20px;
}

.form-layout {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px 20px;
}

.form-group {
    width: 100%;
}

@media (min-width: 768px) {
    .form-layout {
        grid-template-columns: 1fr 1fr;
    }

    .button-group {
        grid-column: span 2;
    }
}
</style>
