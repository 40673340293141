<template>
    <PublicLayout>
        <div class="container">
            <h2>Verifica tu teléfono</h2>
            <p>Hemos enviado un código de verificación a <strong>{{ formattedPhoneNumber }}</strong> por WhatsApp.</p>
            <p v-if="errorMessage" class="error-msg">{{ errorMessage }}</p>
            <div class="otp-input-container">
                <input v-for="(digit, index) in otp" :key="index" type="text" maxlength="1"
                    :class="{ 'otp-input': true, 'error-border': errorMessage }" v-model="otp[index]"
                    @input="focusNext(index, $event)" />
            </div>
            <a class="label" v-if="timer > 0">
                Podrás solicitar uno nuevo en <strong>{{ formattedTime }}</strong>.
            </a>
            <a class="link label" v-else @click="requestNewOtp">Solicitar nuevo código</a>

            <div class="otp-footer">
                <a class="link label" @click="changePhoneNumber">Cambiar de número</a>
            </div>
            <FullScreenLoader :isLoading="isLoading" />
        </div>
    </PublicLayout>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout.vue';
import FullScreenLoader from '@/components/FullScreenLoader.vue';

export default {
    name: "OTPView",
    components: {
        PublicLayout,
        FullScreenLoader
    },
    data() {
        return {
            phoneNumber: "",
            otp: ["", "", "", ""],
            timer: 60,
            errorMessage: "",
            isLoading: false,
        };
    },
    computed: {
        formattedPhoneNumber() {
            return `+52${this.phoneNumber}`;
        },
        formattedTime() {
            const minutes = Math.floor(this.timer / 60);
            const seconds = this.timer % 60;
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        },
        isOtpComplete() {
            return this.otp.every(digit => digit !== "");
        }
    },
    watch: {
        otp: {
            deep: true,
            handler() {
                if (this.isOtpComplete) {
                    this.submitOtp();
                }
            }
        }
    },
    methods: {
        focusNext(index, event) {
            if (event.target.value !== "" && index < 3) {
                const nextInput = event.target.nextElementSibling;
                if (nextInput) {
                    nextInput.focus();
                }
            }
        },
        async submitOtp() {
            this.isLoading = true;
            const enteredOtp = this.otp.join("");

            try {
                const response = await fetch('https://api.hey-matchr.com/session/otp/validate', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        phoneNumber: `+52${this.phoneNumber}`,
                        otpCode: enteredOtp
                    })
                });

                const data = await response.json();
                if (data.statusCode === 200) {
                    const responseBody = JSON.parse(data.body);
                    const token = responseBody.token;
                    const enrollmentStatus = responseBody.enrollment_status;

                    localStorage.setItem('authToken', token);
                    this.errorMessage = "";

                    if (enrollmentStatus === "0") {
                        localStorage.setItem('pageTitle', 'Cuentanos sobre ti');
                        this.$router.push({ name: 'MusicianProfileView' });
                    } else if (enrollmentStatus === "1") {
                        const userProfile = await this.getUserProfile();
                        localStorage.setItem('userProfile', JSON.stringify(userProfile));
                        localStorage.setItem('pageTitle', 'Completa tu perfil');
                        this.$router.push({ name: 'MusicianProfileView' });
                    } else if (enrollmentStatus === "2") {
                        const musicianProfile = await this.getMusicianProfile();
                        localStorage.setItem('musicianProfile', JSON.stringify(musicianProfile));
                        localStorage.setItem('pageTitle', 'Completa tu Solicitud');
                        this.$router.push({ name: 'RequestDetailView' });
                    } else if (enrollmentStatus === "3") {
                        const musicianProfile = await this.getMusicianProfile();
                        localStorage.setItem('musicianProfile', JSON.stringify(musicianProfile));
                        localStorage.setItem('pageTitle', 'Completa tu Solicitud');
                        this.$router.push({ name: 'RequestDetailView' });
                    } else {
                        const musicianProfile = await this.getMusicianProfile();
                        localStorage.setItem('musicianProfile', JSON.stringify(musicianProfile));
                        this.$router.push({ name: 'matches' });
                    }
                } else if (data.statusCode === 400) {
                    this.errorMessage = "El código que ingresaste es inválido o ha expirado.";
                    this.resetOtp();
                }
            } catch (error) {
                console.error('Error en submitOtp:', error);
                this.errorMessage = "Error de conexión. Intente nuevamente.";
            } finally {
                this.isLoading = false;
            }
        },
        async getUserProfile() {
            console.log("Obteniendo el perfil del usuario...");
            const token = localStorage.getItem('authToken');

            try {
                const response = await fetch('https://api.hey-matchr.com/users/getUserProfile', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                const data = await response.json();
                if (data.statusCode === 200) {
                    const responseBody = JSON.parse(data.body);
                    const userProfile = responseBody.userProfile;

                    const sanitizedUserProfile = Object.fromEntries(
                        Object.entries(userProfile).filter(([, value]) => value !== null)
                    );

                    return sanitizedUserProfile;
                } else {
                    console.error("Error al obtener el perfil de usuario:", data);
                }
            } catch (error) {
                console.error("Error en la solicitud getUserProfile:", error);
            }
            return {};
        },
        async getMusicianProfile() {
            console.log("Obteniendo el perfil de músico...");
            const token = localStorage.getItem('authToken');

            try {
                const response = await fetch('https://api.hey-matchr.com/users/getMusicianProfile', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                const data = await response.json();
                if (data.statusCode === 200) {
                    const responseBody = JSON.parse(data.body);
                    const musicianProfile = responseBody.musicianProfile;

                    const sanitizedMusicianProfile = Object.fromEntries(
                        Object.entries(musicianProfile).filter(([, value]) => value !== null)
                    );

                    return sanitizedMusicianProfile;
                } else {
                    console.error("Error al obtener el perfil de músico:", data);
                }
            } catch (error) {
                console.error("Error en la solicitud getMusicianProfile:", error);
            }
            return {};
        },
        resetOtp() {
            this.otp = ["", "", "", ""];
            document.querySelector('.otp-input-container input').focus();
        },
        async requestNewOtp() {
            this.isLoading = true;
            try {
                const response = await fetch('https://api.hey-matchr.com/session/otp/send', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ phoneNumber: `+52${this.phoneNumber}` })
                });

                const data = await response.json();
                if (data.statusCode === 429) {
                    this.errorMessage = "Has alcanzado el límite de intentos. Por favor, inténtalo de nuevo más tarde.";
                } else if (response.ok) {
                    this.timer = 60;
                    this.errorMessage = "";
                } else {
                    this.errorMessage = data.message || 'Error enviando el OTP. Intente nuevamente.';
                }
            } catch (error) {
                console.error('Error en requestNewOtp:', error);
                this.errorMessage = "Error de conexión. Intente nuevamente.";
            } finally {
                this.isLoading = false;
            }
        },
        changePhoneNumber() {
            this.$router.push({ name: 'SignUp', params: { phoneNumber: this.phoneNumber } });
        }
    },
    mounted() {
        const countdown = setInterval(() => {
            if (this.timer > 0) {
                this.timer--;
            } else {
                clearInterval(countdown);
            }
        }, 1000);

        this.phoneNumber = sessionStorage.getItem('phoneNumber') || "";
    }
};
</script>

<style scoped>
.otp-input-container {
    display: flex;
    justify-content: space-between;
}

.otp-input {
    width: 35px;
    height: 50px;
    text-align: center;
    font-size: 1.5em;
    border: 1px solid var(--color-primary-bold);
    border-radius: 5px;
    background-color: var(--color-neutral-1);
    color: var(--neutral-dark);
    margin: 5px;
}

.otp-input:focus {
    outline: none;
    border-color: var(--color-primary-light);
}

.otp-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}
</style>
