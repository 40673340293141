<template>
    <div class="tag-input">
        <div class="input-with-chips">
            <div class="chip-inside-input">
                <!-- Mostrar los chips seleccionados -->
                <span v-for="chip in selectedChips" :key="chip" class="chip selected">
                    {{ chip }}
                    <span class="remove-chip" @click="removeChip(chip)">✕</span>
                </span>
                <!-- Input para buscar o añadir géneros, se deshabilita si se alcanzó el límite -->
                <input 
                    type="text" 
                    :placeholder="showPlaceholder ? placeholderText : ''" 
                    class="scrollable-input"
                    v-model="inputValue" 
                    @input="filterChips" 
                    @keydown.enter.prevent="addCustomChip" 
                    @keydown.backspace="handleBackspace" 
                    ref="chipInput" 
                    :disabled="selectedChips.length >= limit" 
                />
            </div>
        </div>
        <!-- Chips en estado natural (con fondo rosa suave, seleccionables o deshabilitados si se alcanzó el límite) -->
        <div class="chip-container">
            <span 
                v-for="chip in visibleChips" 
                :key="chip" 
                class="chip" 
                :class="{ disabled: selectedChips.length >= limit }" 
                @click="selectChip(chip)" 
                :style="{ pointerEvents: selectedChips.length >= limit ? 'none' : 'auto' }">
                {{ chip }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TagInput',
    props: {
        availableOptions: {
            type: Array,
            required: true
        },
        modelValue: {
            type: Array,
            default: () => []
        },
        limit: {
            type: Number,
            default: 3 // Por defecto, se pueden seleccionar hasta 3 chips
        }
    },
    data() {
        return {
            selectedChips: [...this.modelValue],  // Inicializa los chips seleccionados a partir de `modelValue`
            inputValue: '',                       // Texto actual en el input
            filteredChips: [],                    // Chips filtrados según el input
            chipsToShow: 4                        // Mostrar siempre 4 chips
        };
    },
    watch: {
        // Verifica los cambios en `modelValue` para actualizar `selectedChips` cuando sea necesario
        modelValue(newVal) {
            this.selectedChips = [...newVal];
        }
    },
    computed: {
        showPlaceholder() {
            return this.selectedChips.length === 0 && this.inputValue === '';
        },
        unselectedChips() {
            return this.availableOptions.filter(chip => !this.selectedChips.includes(chip));
        },
        visibleChips() {
            return this.inputValue === '' ? this.unselectedChips.slice(0, this.chipsToShow) : this.filteredChips.slice(0, this.chipsToShow);
        },
        placeholderText() {
            return this.limit === 1 ? 'Selecciona una opción' : `Selecciona hasta ${this.limit} opciones`;
        }
    },
    methods: {
        normalizeText(text) {
            return text.trim().toLowerCase().replace(/[\s-]+/g, ' ');
        },
        filterChips() {
            const searchText = this.normalizeText(this.inputValue);
            this.filteredChips = this.unselectedChips.filter(chip =>
                this.normalizeText(chip).includes(searchText)
            );
        },
        removeChip(chip) {
            this.selectedChips = this.selectedChips.filter(c => c !== chip);
            this.updateChips();
        },
        selectChip(chip) {
            if (!this.selectedChips.includes(chip) && this.selectedChips.length < this.limit) {
                this.selectedChips.push(chip);
                this.updateChips();
            }
            this.inputValue = '';
            this.filterChips();
            this.$nextTick(() => {
                this.$refs.chipInput.focus();
            });
        },
        addCustomChip() {
            if (this.selectedChips.length >= this.limit) return;

            const normalizedInput = this.normalizeText(this.inputValue);
            const existingChip = this.availableOptions.find(chip =>
                this.normalizeText(chip) === normalizedInput
            );

            if (existingChip && !this.selectedChips.includes(existingChip)) {
                this.selectedChips.push(existingChip);
            } else if (!existingChip && normalizedInput) {
                this.selectedChips.push(this.inputValue);
            }

            this.inputValue = '';
            this.filterChips();
            this.updateChips();
        },
        handleBackspace() {
            if (this.inputValue === '' && this.selectedChips.length > 0) {
                this.selectedChips.pop();
                this.updateChips();
            }
        },
        updateChips() {
            this.$emit('update:modelValue', this.selectedChips);  // Emite el valor actualizado
        }
    }
};
</script>

<style scoped>
.chip .remove-chip {
    cursor: pointer;
    display: inline-block;
}

input {
    padding: 10px 7px;
}
</style>
