<template>
  <PublicLayout>
    <div class="container">
      <h2>Bienvenid@ 🎸</h2>
      <p>
        MaTChR es la plataforma que une a músicos y bandas, ya sea que busques tocar por puro gusto o quieras encontrar
        trabajo. ¿Covers clásicos o música original? Con MaTChR encontrarás músicos que comparten tu misma pasión y
        estilo, listos para crear juntos.
      </p>
      <p>No importa tu nivel de habilidad, tu zona de ensayo, disponibilidad de horarios o el género que te inspire.
        Desde tus influencias hasta tu repertorio, MaTChR se encarga de encontrar el match perfecto para ti.</p>
      <p>
        💥 ¡Regístrate hoy y súmate a la revolución musical! 💥
      </p>
      <router-link :to="{ name: 'SignUp', query: { from: 'signup' } }" class="cta-button">¡Regístrate Ahora!</router-link>
      <router-link :to="{ name: 'SignUp', query: { from: 'login' } }" class="link label">Inicia Sesión</router-link>
    </div>
  </PublicLayout>
</template>


<script>
import PublicLayout from '@/layouts/PublicLayout.vue';

export default {
  name: 'LandingPage',
  components: {
    PublicLayout
  },
  metaInfo() {
    return {
      title: 'MaTChR - Conecta Músicos y Bandas en México | Pre-registro',
      meta: [
        { name: 'description', content: 'Únete a MaTChR, la plataforma que conecta músicos y bandas en México. Regístrate para encontrar tu match musical perfecto.' },
        { name: 'keywords', content: 'músicos, bandas, México, conexión musical, pre-registro, MaTChR, Edo.Mex, busco vocalista, busco guitarrista, busco bajista, CDMX, busco musicos, huesero, salas de ensayo, ensayo, banda, rock' },
        { property: 'og:title', content: 'MaTChR - Conecta Músicos y Bandas en México' },
        { property: 'og:description', content: 'Únete a MaTChR, la plataforma que conecta músicos y bandas en México.' },
        { property: 'og:image', content: 'https://hey-matchr.com/logo.png' },
        { property: 'og:url', content: 'https://hey-matchr.com/' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'MaTChR - Conecta Músicos y Bandas en México' },
        { name: 'twitter:description', content: 'Únete a MaTChR, la plataforma que conecta músicos y bandas en México.' },
        { name: 'twitter:image', content: 'https://hey-matchr.com/logo.png' }
      ]
    }
  }
}
</script>

<style scoped></style>