<template>
    <PublicLayout>
        <div class="container">
            <h2>{{ pageTitle }}</h2>
            <p>{{ parragraph }}</p>
            <p v-if="errorMessage" class="error-msg">{{ errorMessage }}</p>
            <div class="input-container">
                <div class="input-wrapper">
                    <span class="flag-prefix">
                        <img src="@/assets/mexico-flag.png" alt="México" />
                    </span>
                    <span class="prefix">+52</span>
                    <input type="text" v-model="phoneNumber" @input="validatePhoneNumber"
                        placeholder="Número de teléfono" maxlength="10" />
                </div>
            </div>
            <button :disabled="!isValid || isLoading" class="cta-button" @click="goToOTP">
                {{ isLoading ? "Enviando..." : "Continuar" }}
            </button>
            <FullScreenLoader :isLoading="isLoading" />
        </div>
    </PublicLayout>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout.vue';
import FullScreenLoader from '@/components/FullScreenLoader.vue';

export default {
    name: "SignUpView",
    components: {
        PublicLayout,
        FullScreenLoader
    },
    data() {
        return {
            phoneNumber: "",
            errorMessage: "",
            isValid: false,
            isLoading: false,
            pageTitle: ""
        };
    },
    methods: {
        validatePhoneNumber() {
            const sequentialRegex = /(01234|12345|23456|34567|45678|56789|67890)/;
            const repeatedRegex = /(\d)\1{3,}/;
            const nonNumericRegex = /\D/;

            if (this.phoneNumber.match(nonNumericRegex)) {
                this.errorMessage = "Solo se permiten números.";
                this.isValid = false;
                return;
            }

            if (this.phoneNumber.length !== 10) {
                this.errorMessage = "Número inválido";
                this.isValid = false;
                return;
            }

            if (sequentialRegex.test(this.phoneNumber)) {
                this.errorMessage = "Número inválido";
                this.isValid = false;
                return;
            }

            if (repeatedRegex.test(this.phoneNumber)) {
                this.errorMessage = "Número inválido";
                this.isValid = false;
                return;
            }

            this.errorMessage = "";
            this.isValid = true;
        },
        async sendOtpRequest() {
            this.isLoading = true;
            try {
                const response = await fetch('https://api.hey-matchr.com/session/otp/send', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ phoneNumber: `+52${this.phoneNumber}` })
                });

                const data = await response.json();

                if (data.statusCode === 429) {
                    this.errorMessage = "Has alcanzado el límite de intentos. Por favor, inténtalo de nuevo más tarde.";
                    this.isValid = false;
                } else if (response.ok) {
                    sessionStorage.setItem('phoneNumber', this.phoneNumber);
                    this.$router.push({ name: 'OTP' });
                } else {
                    this.errorMessage = data.message || 'Error enviando el OTP. Intente nuevamente.';
                }
            } catch (error) {
                console.error('Error en sendOtpRequest:', error);
                this.errorMessage = 'Error de conexión. Intente nuevamente.';
            } finally {
                this.isLoading = false;
            }
        },
        async goToOTP() {
            this.validatePhoneNumber();
            if (this.isValid) {
                await this.sendOtpRequest();
            }
        }
    },
    mounted() {
        // Set the page title based on the `from` query parameter
        const from = this.$route.query.from;
        this.pageTitle = from === 'login' ? "Nuevas oportunidades te esperan" : "Regístrate y haz MaTCh";
        this.parragraph = from === 'login' ? "Accede y encuentra el MaTCh perfecto." : "Comienza tu aventura musical con quienes comparten tu pasión. Inicia aquí.";
    }
};
</script>


<style scoped>
.input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.input-wrapper {
    position: relative;
    width: 100%;
    max-width: 300px;
}

.flag-prefix {
    position: absolute;
    top: 50%;
    left: 4%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}

.flag-prefix img {
    width: 20px;
    height: auto;
}

.prefix {
    position: absolute;
    top: 50%;
    left: 12%;
    transform: translateY(-50%);
    color: var(--neutral-dark);
    opacity: 0.8;
    pointer-events: none;
}

input[type="text"] {
    padding: 10px 10px 10px 70px;
}

@media (max-width: 599px) {
    .prefix {
        left: 12%;
    }
}
</style>
