<template>
  <header class="main-header">
    <!-- Logo -->
    <div class="logo">
      <img src="@/assets/logo-horizontal.svg" alt="Logo" class="logo-img" />
    </div>

    <!-- Ayuda para Web y Mobile -->
    <div class="help" @click="goToHelp">
      <i class="fa-regular fa-life-ring header-icon"></i>
      <span class="help-text" v-if="!isMobileView">Ayuda</span>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  props: {
    isMobileView: Boolean,
    isPrivate: Boolean
  },
  methods: {
    goToHelp() {
      window.open('https://wa.me/+525659070460', '_blank');
    }
  }
};
</script>

<style scoped>
.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-dark-3);
  width: 100%;
  padding: 10px 30px 0px 20px;
  z-index: 10;
  box-sizing: border-box;
}

.logo-img {
  width: 180px;
}

.help {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header-icon {
  font-size: 1.5em;
  color: var(--color-neutral-1);
}

.help-text {
  color: var(--color-neutral-1);
  font-size: 1em;
  margin-left: 10px;
}
</style>
