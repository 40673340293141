<template>
    <PublicLayout>
        <div class="container">
            <h2>Cuéntanos más sobre tu banda</h2>
            <form @submit.prevent="submitForm">
                <!-- Nombre de la banda -->
                <div class="form-group">
                    <label for="bandName" class="form-input-title">Nombre de la banda</label>
                    <input id="bandName" v-model="bandName" type="text" placeholder="Escribe el nombre de tu banda" />
                </div>

                <!-- Nivel de la banda -->
                <div class="form-group">
                    <label for="bandLevel" class="form-input-title">Nivel de la banda</label>
                    <tag-input v-model="selectedBandLevel" :available-options="availableBandLevels" :limit="1" />
                </div>

                <!-- ¿Qué estás buscando? -->
                <div class="form-group">
                    <label for="lookingFor" class="form-input-title">¿Qué estás buscando?</label>
                    <tag-input v-model="selectedInstruments" :available-options="availableInstruments" :limit="5" />
                </div>
                <div v-if="isFormValid" class="informative-text">
                    ¡Estás más cerca de tu MaTCh! Agrega más detalles para aumentar tus oportunidades de encontrar la
                    banda o los músicos perfectos.
                </div>
                <div v-if="isFormValid">
                    <div class="button-group">
                        <button @click="goToSimpleSearch" class="cta-button" :disabled="isLoading">Quiero encontrar mi MaTCh</button>
                    </div>
                </div>
            </form>
            <FullScreenLoader :isLoading="isLoading" />
        </div>
    </PublicLayout>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout.vue';
import TagInput from '@/components/TagInput.vue';
import FullScreenLoader from '@/components/FullScreenLoader.vue';

export default {
    name: 'BandProfileView',
    components: {
        PublicLayout,
        TagInput,
        FullScreenLoader
    },
    data() {
        return {
            bandName: '', // Nombre de la banda
            selectedBandLevel: [], // Nivel de la banda
            selectedInstruments: [], // Instrumentos buscados
            availableBandLevels: ['Principiante', 'Intermedio', 'Avanzado'], // Opciones de nivel de banda
            availableInstruments: ['Guitarra', 'Bajo', 'Batería', 'Voz', 'Teclado', 'Segunda Guitarra', 'Coros', 'Percusiones'], // Instrumentos buscados
            isLoading: false
        };
    },
    computed: {
        isFormValid() {
            // Validar si todos los campos están completos
            return this.bandName.length > 0 && this.selectedBandLevel.length > 0 && this.selectedInstruments.length > 0;
        }
    },
    methods: {
        async submitForm() {
            if (this.isLoading) return;
            this.isLoading = true;

            const token = localStorage.getItem('authToken');
            const musicianProfile = JSON.parse(localStorage.getItem('musicianProfile')) || {};

            // Mapeo del nivel de la banda a valores de la BD
            const bandLevelMap = {
                'Principiante': 'BEGINNER',
                'Intermedio': 'INTERMEDIATE',
                'Avanzado': 'ADVANCED'
            };
            const mappedBandLevel = bandLevelMap[this.selectedBandLevel[0]];

            // Datos para la creación de la banda
            const bandData = {
                band_name: this.bandName,
                band_level: mappedBandLevel,
                genres: musicianProfile.genres || [],
                music_type: musicianProfile.music_type || 'BOTH',
                interest: musicianProfile.interest || 'HOBBY'
            };

            try {
                const bandResponse = await fetch('https://api.hey-matchr.com/bands/enrichment', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(bandData)
                });
                const bandResult = await bandResponse.json();
                const parsedBandBody = JSON.parse(bandResult.body);

                if (parsedBandBody.message === "Band profile enriched successfully.") {
                    const bandId = parsedBandBody.band_profile.band_id;
                    const requestsCreated = await this.createRequests(bandId, musicianProfile);

                    if (requestsCreated) {
                        await this.updateEnrollmentStatus(token);
                    }
                    return true;
                } else {
                    console.log("La respuesta de la API de creación de banda no fue exitosa.");
                    return false;
                }
            } catch (error) {
                console.error("Error en la solicitud de creación de banda:", error);
                return false;
            } finally {
                this.isLoading = false;
            }
        },
        async goToSimpleSearch() {
            const success = await this.submitForm();
            if (success) {
                this.$router.push({ name: 'matches' });
            }
        },
        async createRequests(bandId, musicianProfile) {
            const token = localStorage.getItem('authToken');
            let allRequestsSuccessful = true;

            for (let instrument of this.selectedInstruments) {
                const requestBody = {
                    genres: musicianProfile.genres || [],
                    influences: musicianProfile.influences || null,
                    level: musicianProfile.skill_level || 'INTERMEDIATE',
                    music_type: musicianProfile.music_type || 'COVERS',
                    interest: musicianProfile.interest || 'HOBBY',
                    request_type: 'MUSICIAN',
                    instrument: instrument,
                    band_id: bandId
                };

                try {
                    const requestResponse = await fetch('https://api.hey-matchr.com/requests/create', {
                        method: 'PUT',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(requestBody)
                    });

                    const requestResult = await requestResponse.json();
                    if (requestResult.statusCode === 200) {
                        const parsedRequestBody = JSON.parse(requestResult.body);
                        const requestId = parsedRequestBody.request.request_id;
                        sessionStorage.setItem(`request_${instrument}`, requestId);
                        console.log(`Request creado exitosamente para el instrumento: ${instrument}`);
                    } else {
                        console.log("Error en la creación de request:", requestResult);
                        allRequestsSuccessful = false;
                    }
                } catch (error) {
                    console.error(`Error creando request para el instrumento ${instrument}:`, error);
                    allRequestsSuccessful = false;
                }
            }
            return allRequestsSuccessful;
        },
        async updateEnrollmentStatus(token) {
            const updateRequest = {
                user: {
                    enrollment_status: "4"
                }
            };
            try {
                const responseUpdate = await fetch('https://api.hey-matchr.com/users/enrichment', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updateRequest)
                });

                const dataUpdated = await responseUpdate.json();
                const parsedUpdatedBody = JSON.parse(dataUpdated.body);

                if (parsedUpdatedBody.message === "Profile enrichment process completed.") {
                    localStorage.setItem('userProfile', JSON.stringify(parsedUpdatedBody.user));
                    console.log("Enrollment status updated successfully.");
                    return true;
                } else {
                    console.log("Failed to update enrollment status:", parsedUpdatedBody);
                    return false;
                }
            } catch (error) {
                console.error("Error updating enrollment status:", error);
                return false;
            }
        }
    }
};
</script>

<style scoped>
.button-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

button.cta-button {
    padding: 10px 20px;
}

.form-group {
    margin-bottom: 20px;
    width: 100%;
}
</style>
