<template>
  <footer class="footer">
    <div class="footer-content">
      <!-- Contacto por Email y WhatsApp -->
      <div class="footer-section footer-info">
        <a href="mailto:hola.matchr@gmail.com" target="_blank">
          <i class="fa-regular fa-envelope"></i>
          <span>hola.matchr@gmail.com</span>
        </a>
        <a href="https://wa.me/+525659070460" target="_blank">
          <i class="fa-brands fa-whatsapp"></i>
          <span>+52 5659 070 460</span>
        </a>
      </div>

      <!-- Redes sociales -->
      <div class="footer-section footer-info">
        <a href="https://www.instagram.com/hey_matchr" target="_blank">
          <i class="fa-brands fa-instagram"></i>
          <span>@hey_matchr</span>
        </a>
        <a href="https://www.facebook.com/profile.php?id=61563834422789" target="_blank">
          <i class="fa-brands fa-facebook-f"></i>
          <span>@Matchr</span>
        </a>
      </div>

      <!-- Información Legal -->
      <div class="footer-section footer-info">
        <a href="/privacidad">
          <i class="fa-solid fa-file"></i>
          <span>Aviso de Privacidad</span>
        </a>
      </div>
    </div>

    <!-- Versión de la aplicación en la parte inferior -->
    <div class="app-version">
      <p>© 2024 MaTChR. Todos los derechos reservados. | Version v0.1.0</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
.footer {
  background-color: var(--color-dark-3);
  padding: 20px 40px 10px 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0; /* Asegura que el footer no colapse en altura */
}

/* Estilos del contenido del footer */
.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

/* Estilo compartido para Contacto y Redes sociales */
.footer-info a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color-neutral-1);
  font-size: 0.9em;
  margin-bottom: 10px;
}

.footer-info i {
  font-size: 1.2em;
  margin-right: 10px;
  transition: transform 0.3s ease;
  color: var(--color-secondary-bold);
}

.footer-info i:hover {
  transform: scale(1.1);
}

.footer-info span {
  color: var(--color-neutral-1);
}

/* Estilos para la versión de la app */
.app-version {
  text-align: center;
  padding-top: 10px;
}

.app-version p {
  text-align: center;
  font-size: 0.8em;
  margin: 0;
  color: var(--color-secondary-light);
}

@media (max-width: 599px) {
  .footer{
    padding:25px;
  }
  .footer-content {
    align-items: center;
  }

  .footer-info {
    text-align: right;
    margin-bottom: 10px;
  }
  
  .app-version {
    padding-top: 10px;
  }
}
</style>
