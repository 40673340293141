<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import './assets/styles/global.css'; /* Importar los estilos globales */

/* Asegura que el contenedor ocupe toda la pantalla */
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
