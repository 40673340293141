<template>
    <div class="main-layout">
      <!-- Header -->
      <AppHeader
        :isMobileView="isMobileView"
        :mobileMenuOpen="mobileMenuOpen"
        :isPrivate="true"
        @toggle-mobile-menu="toggleMobileMenu"
      />
  
      <div class="layout-body">
        <!-- Menú lateral (Web) -->
        <AppSidebar
          v-if="!isMobileView"
          :isSidebarCollapsed="isSidebarCollapsed"
          @toggle-sidebar="toggleSidebar"
          @go-to="goTo"
          @is-active="isActive"
        />
  
        <!-- Contenido Principal -->
        <main class="main-content" :class="{ 'mobile-content': isMobileView }">
          <router-view></router-view>
        </main>
      </div>
  
      <!-- Footer para vista web -->
      <footer class="footer" v-if="!isMobileView">
        <AppFooter />
      </footer>
  
      <!-- NavBar para vista móvil -->
      <NavBar v-if="isMobileView" />
    </div>
  </template>
  
  <script>
  import AppFooter from '@/components/AppFooter.vue';
  import AppHeader from '@/components/AppHeader.vue';
  import AppSidebar from '@/components/AppSidebar.vue';
  import NavBar from '@/components/NavBar.vue'; 
  
  export default {
    name: 'MainLayout',
    components: {
      AppHeader,
      AppFooter,
      AppSidebar,
      NavBar, 
    },
    data() {
      return {
        isSidebarCollapsed: false,
        isMobileView: false,
        mobileMenuOpen: false,
      };
    },
    methods: {
      toggleSidebar() {
        this.isSidebarCollapsed = !this.isSidebarCollapsed;
      },
      toggleMobileMenu() {
        this.mobileMenuOpen = !this.mobileMenuOpen;
      },
      goTo(section) {
        this.$router.push({ name: section });
      },
      isActive(section) {
        return this.$route.name === section;
      },
      checkMobileView() {
        this.isMobileView = window.innerWidth <= 768;
      },
    },
    mounted() {
      this.checkMobileView();
      window.addEventListener('resize', this.checkMobileView);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.checkMobileView);
    },
  };
  </script>
  
  <style scoped>
  /* Layout Principal */
  .main-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  /* Cuerpo del layout */
  .layout-body {
    display: flex;
    flex-grow: 1;
    height: calc(100vh - 140px); /* Altura entre header y footer */
    overflow-y: auto; /* Asegura que el contenido no desborde */
  }
  
  /* Contenido Principal */
  .main-content {
    flex-grow: 1;
    padding: 20px;
    background-color: var(--color-neutral-1);
    overflow-y: auto;
  }
  
  .main-content.mobile-content {
    margin-top: 0;
    padding-bottom: 60px; /* Espacio adicional para el NavBar móvil */
  }
  
  /* Footer */
  .footer {
    flex-shrink: 0;
  }

  </style>
  