<template>
  <div v-if="isLoading" class="loader-overlay">
      <div class="loader-container">
          <video autoplay loop muted playsinline class="loader-video">
              <source :src="animationPath" type="video/webm" />
          </video>
      </div>
  </div>
</template>

<script>
export default {
  name: "FullScreenLoader",
  props: {
      isLoading: {
          type: Boolean,
          required: true,
      },
  },
  data() {
      return {
          animationPath: require('@/assets/loader_purple.webm')
      };
  },
};
</script>

<style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader-container {
  background-color: var(--hover-bg);
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--color-primary-bold);
}

</style>
