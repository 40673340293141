<template>
    <div class="main-content">
      <div class="requests-title">🚀 Falta Poco!</div>
      <p class="requests-text">
        Aquí podrás gestionar todas tus solicitudes activas y perfeccionar cada detalle para encontrar el MaTCh perfecto. Añade información específica como tus influencias, subgéneros, zonas de ensayo, disponibilidad, ¡y mucho más!
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'RequestsView',
  };
  </script>
  
  <style scoped>
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 60%; /* Centrado verticalmente */
    padding: 20px;
    background-color: var(--color-neutral-1);
  }
  
  .requests-title {
    color: var(--color-dark-1); /* Color oscuro para destacar */
    font-size: 2em;
    margin: 15px 0;
  }
  
  .requests-text {
    color: var(--color-secondary-bold); /* Color secundario para contraste */
    font-size: 1.2em;
    margin-top: 10px;
    max-width: 400px;
  }
  </style>
  