<template>
  <div class="main-content">
    <div class="matches-title">🚧 Muy Pronto</div>
    <p class="matches-text">
      Pronto podrás encontrar aquí los MaTChEs ideales que hemos seleccionado para ti. Además, podrás explorar y filtrar entre las solicitudes de otras bandas y músicos para descubrir oportunidades perfectas. 🚀
    </p>
  </div>
</template>

<script>
export default {
  name: 'MatchesView',
};
</script>

<style scoped>
.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 60%; /* Centrado verticalmente */
  padding: 20px;
  background-color: var(--color-neutral-1);
}

.matches-title {
  color: var(--color-dark-1); /* Color oscuro para destacar */
  font-size: 2em;
  margin: 15px 0;
}

.matches-text {
  color: var(--color-secondary-bold); /* Color secundario para contraste */
  font-size: 1.2em;
  margin-top: 10px;
  max-width: 400px;
}
</style>
