<template>
    <nav class="mobile-nav">
        <ul>
            <li @click="goTo('RequestsView')" :class="{ active: isActive('RequestsView') }">
                <div class="icon-wrapper">
                    <i class="fa-solid fa-paper-plane"></i>
                </div>
                <span>Solicitudes</span>
            </li>
            <li @click="goTo('matches')" :class="{ active: isActive('matches') }">
                <div class="icon-wrapper">
                    <i class="fa-solid fa-compact-disc"></i>
                </div>
                <span>Matches</span>
            </li>
            <li @click="goTo('BandsView')" :class="{ active: isActive('BandsView') }">
                <div class="icon-wrapper">
                    <i class="fa-solid fa-bolt"></i>
                </div>
                <span>Bandas</span>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'AppNavBar',
    methods: {
        goTo(section) {
            this.$router.push({ name: section });
        },
        isActive(section) {
            return this.$route.name === section;
        },
    },
};
</script>

<style scoped>
.mobile-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--color-dark-3);
    border-radius: 20px 20px 0px 0px;
    z-index: 100;
}

.mobile-nav ul {
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
}

.mobile-nav li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin-top:5px;
}

.mobile-nav li span {
    font-size: 0.9em;
    color: var(--color-neutral-1);
    margin-top: 10px;
}

.icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 2px solid var(--color-primary-bold); /* Borde fijo */
    border-radius: 50%;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.icon-wrapper i {
    font-size: 1.2em;
    color: var(--color-neutral-1);
}

/* Añade el glow solo cuando el elemento está activo */
.mobile-nav li.active .icon-wrapper {
    box-shadow: 0px 0px 4px 6px rgba(255, 51, 204, 0.5); /* Glow rosa */
    border-color: var(--color-primary-light);
}
</style>
