<template>
    <div class="main-content">
      <div class="bands-title">🚧 Próximamente</div>
      <p class="bands-text">
        Aquí podrás encontrar toda la información de las bandas a las que perteneces, actualizar sus perfiles para lograr el MaTCh perfecto, y hasta invitar a nuevos miembros para ganar premios exclusivos. ¡Todo en un solo lugar! 🎶
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BandsView',
  };
  </script>
  
  <style scoped>
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 60%; /* Centrado verticalmente */
    padding: 20px;
    background-color: var(--color-neutral-1);
  }
  
  .bands-title {
    color: var(--color-dark-1); /* Color oscuro para destacar */
    font-size: 2em;
    margin: 15px 0;
  }
  
  .bands-text {
    color: var(--color-secondary-bold); /* Color secundario para contraste */
    font-size: 1.2em;
    margin-top: 10px;
    max-width: 400px;
  }
  </style>
  