<template>
  <div class="public-layout">
    <!-- Header siempre visible -->
    <AppHeader />

      <div class="main-content">
        <slot></slot>
      </div>
    <!-- Footer siempre visible -->
    <footer>
      <AppFooter />
    </footer>
  </div>
</template>

<script>
import AppFooter from '@/components/AppFooter.vue';
import AppHeader from '@/components/AppHeader.vue';

export default {
  name: 'PublicLayout',
  components: {
    AppFooter,
    AppHeader,
  }
}
</script>

<style scoped>
.public-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  flex-grow: 1;
  background-color: var(--color-dark-2);
  display: flex;
  justify-content: center;
}

/* Ajustes específicos para pantallas móviles */
@media (max-width: 599px) {

  .main-content {
    justify-content: flex-start; /* Alinea el contenido hacia arriba */
    display: block;
    padding: 20px;
  }
}
</style>
