import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createMetaManager } from 'vue-meta';
import '@fortawesome/fontawesome-free/css/all.css';


const app = createApp(App);
const metaManager = createMetaManager();
app.use(metaManager);
app.use(router);
app.mount('#app');
