<template>
    <div class="privacy">
        <h1>Aviso de Privacidad</h1>
        <p>
            En cumplimiento con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares,
            Jorge Omar Martínez Mendieta, en representación de MaTChR, con domicilio en Canela 484, Colonia Granjas
            México, Ciudad de México, C.P. 08400,
            hace de su conocimiento el presente Aviso de Privacidad.
        </p>

        <h2>Finalidad del Tratamiento de Datos</h2>
        <p>Sus datos personales serán utilizados para las siguientes finalidades:</p>
        <ul>
            <li>Conectarlos con otros músicos de la red.</li>
            <li>Evaluar y encontrar el match perfecto para su perfil musical.</li>
            <li>Publicitar y promover la plataforma MaTChR.</li>
            <li>Personalizar y mejorar su experiencia en la plataforma.</li>
            <li>Enviar comunicaciones y notificaciones relacionadas con la plataforma y sus servicios.</li>
            <li>Realizar análisis y estudios para mejorar la oferta de servicios de MaTChR.</li>
            <li>Administrar y operar la plataforma, incluyendo la gestión de sus cuentas de usuario.</li>
        </ul>

        <h2>Medios para Ejercer los Derechos ARCO</h2>
        <p>
            Usted tiene derecho a acceder, rectificar y cancelar sus datos personales, así como a oponerse al
            tratamiento de los mismos o revocar
            el consentimiento que para tal fin nos haya otorgado, a través del correo electrónico
            <a href="mailto:hola.matchr@gmail.com">hola.matchr@gmail.com</a>.
        </p>

        <h2>Transferencia de Datos Personales</h2>
        <p>
            Sus datos personales podrán ser compartidos con otros usuarios de la plataforma MaTChR con el fin de
            facilitar la conexión y comunicación entre músicos y bandas, siempre con su consentimiento expreso. Los
            datos que podrán ser compartidos incluyen, pero no se limitan a: nombre, teléfono, ubicación y preferencias
            musicales.
        </p>
        <p>
            En todos los demás casos, sus datos personales no serán compartidos con terceros sin su consentimiento,
            salvo en los casos establecidos por la Ley.
        </p>


        <h2>Cambios en el Aviso de Privacidad</h2>
        <p>
            Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente
            aviso de privacidad, los cuales se publicarán en nuestra página web.
        </p>

        <h2>Consentimiento</h2>
        <p>
            Al proporcionar sus datos personales, usted consiente que sean tratados conforme a los términos del presente
            Aviso de Privacidad.
        </p>

        <footer>
            &copy; 2024 MaTChR. Todos los derechos reservados. <br />
            Última actualización: 19 de octubre de 2024.
        </footer>
    </div>
</template>

<script>
export default {
    name: 'PrivacyView',
    metaInfo() {
        return {
            title: 'Aviso de Privacidad - MaTChR',
            meta: [
                { name: 'description', content: 'Aviso de Privacidad de MaTChR.' },
                { name: 'robots', content: 'noindex, nofollow' }  // Evitar indexación
            ]
        }
    }
};
</script>

<style scoped>
/* Estilo discreto y menos llamativo para el aviso de privacidad */
.privacy {
    background-color: var(--neutral-light);
    /* Fondo claro */
    color: var(--neutral-dark);
    /* Texto oscuro */
    padding: 20px;
    max-width: 900px;
    margin: 40px auto;
    /* Centramos y damos margen superior */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Sombra ligera */
    line-height: 1.6;
}

h1 {
    color: var(--neutral-dark);
    /* Título oscuro */
    font-size: 2em;
    text-align: center;
    margin-bottom: 20px;
}

h2 {
    color: var(--primary-accent);
    /* Acentos en color vibrante */
    margin-top: 20px;
    font-size: 1.4em;
}

p,
ul {
    font-size: 1em;
    color: var(--neutral-dark);
    margin-bottom: 20px;
    padding: 0 10px;
}

ul {
    padding-left: 20px;
}

footer {
    text-align: center;
    padding: 15px;
    background-color: var(--neutral-dark);
    color: var(--neutral-light);
    border-radius: 8px;
    margin-top: 40px;
}

/* Media Queries para tablets */
@media (min-width: 600px) and (max-width: 1024px) {
    .privacy {
        padding: 30px;
    }

    h1 {
        font-size: 1.8em;
    }

    h2 {
        font-size: 1.3em;
    }

    p,
    ul {
        font-size: 1.1em;
    }
}

/* Media Queries para dispositivos móviles */
@media (max-width: 599px) {
    .privacy {
        padding: 20px;
    }

    h1 {
        font-size: 1.5em;
    }

    h2 {
        font-size: 1.2em;
    }

    p,
    ul {
        font-size: 1em;
    }
}
</style>